import '../stylesheets/App.css';
import '../stylesheets/CommonCards.css';
import {useState} from "react";
import {Layout} from "./Layout";
import database from "../assets/database.png";
import mods from "../assets/mods.png";
import settings from "../assets/settings.png";
import discord from "../assets/discord-card-feature.png";
import backups from "../assets/backup.png";

export function DiscordBot(){
    const [region, setRegion] = useState("NA")


    return(
        <Layout>
            <div className="Discord body Fader">
                <div className="Plans">
                    <div className="DiscordBot Descriptions SelectedCard">
                        <div className="PackageHeading">
                            <h2>Discord Bot</h2>
                            <p>Starting at $0.75/bot</p>
                        </div>
                        <div className="Features">
                            <li><box-icon type='solid' name='bolt' color='#edeff0'></box-icon>  <p>EPYC 7451 or equivalent</p></li>
                            <li><box-icon name='hdd' type='solid' color='#edeff0' ></box-icon> <p>Upto 20GB Storage</p></li>
                            <li><box-icon name='data' type='solid' color='#edeff0' ></box-icon> <p>Free MySQL Databases - 5</p></li>
                            <li><box-icon name='cloud-lightning' type='solid' color='#edeff0' ></box-icon> <p>Free Backups - 3 Days</p></li>
                        </div>
                        <a className="ViewButton" id="BudgetButton" href="https://billing.humbleservers.com/index.php?rp=/store/discord-bot-hosting">Buy</a>
                    </div>
                </div>

                <br/>
                <br/>
                <br/>
                <h1 className="FeatureHeading">Features with each server!</h1>

                <div className="FeatureCards">
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={database} alt="data"/>
                            <h3>High Speed Data</h3>
                        </div>
                        <p>Say goodbye to slow connections and hello to lightning-fast data speeds at the touch of a button!</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={settings} alt="settings"/>
                            <h3>24/7 Online</h3>
                        </div>
                        <p>Enjoy uninterrupted connectivity and support with our reliable 24/7 online presence, ensuring you're always connected when you need it most, day or night.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={settings} alt="settings"/>
                            <h3>SFTP Access</h3>
                        </div>
                        <p>Effortlessly manage your server files with easy and full SFTP access.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={discord} alt="discord"/>
                            <h3>Discord Support</h3>
                        </div>
                        <p>24/7 Discord support just a message away - get help anytime, anywhere.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={backups} alt="backups"/>
                            <h3>Daily backups</h3>
                        </div>
                        <p>Daily backups for peace of mind - protect your data and never lose progress.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={database} alt="database"/>
                            <h3>Free MySQL</h3>
                        </div>
                        <p>Free MySQL databases - power your server with reliable, fast database storage.</p>
                    </div>
                </div>

            </div>
        </Layout>
    )
}









