import '../stylesheets/App.css';
import '../stylesheets/Locations.css';
import ms from '../assets/ms.png'
import {pingIt} from "../scripts/Ping";
export function Locations() {
    return(
        <div className="Locations body">
            <h1>Connection Speed!</h1>
            <div className="map">
                <div className="dot NewYorkCity">
                </div>
                <div className="dot Germany">
                </div>
            </div>
            <div className="PingResults">
                <p className="Result" id="Location-NYC" style={{display: "none"}}></p>
                <p className="Result" id="Location-EU"  style={{display: "none"}}></p>

            </div>
            <a  className="TestPing" id="pingButton" onClick={() => pingIt()}>
                Test Ping
            </a>
        </div>
    )
}