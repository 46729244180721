import '../stylesheets/App.css';
import '../stylesheets/CommonCards.css';
import {Layout} from "./Layout";
import version from "../assets/version.png";
import mods from "../assets/mods.png";
import settings from "../assets/settings.png";
import discord from "../assets/discord-card-feature.png";
import backups from "../assets/backup.png";
import database from "../assets/database.png";

export function VPS(){

    return(
        <Layout>
            <div className="VPS body Fader">
            <div className="Plans">
                <div className="BudgetDescription Descriptions">
                    <div className="PackageHeading">
                        <h2>Budget</h2>
                        <p>$2/GB</p>
                    </div>
                    <div className="Features">
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>          <p>AMD Epyc 7451 or equivalent </p></li>
                        <li><box-icon name='cloud-upload' color='#D5D5D5' ></box-icon>              <p>Upto 250Mbps Uplink</p></li>
                        <li><box-icon name='dashboard' type='solid' color='#D5D5D5' ></box-icon>    <p>Virtualizor Panel</p>  </li>
                        <li><box-icon name='terminal' color='#D5D5D5' ></box-icon>                  <p>KVM Access</p></li>
                        <li><box-icon name='lock-alt' type='solid' color='#D5D5D5' ></box-icon>     <p>Unmanaged</p> </li>
                    </div>
                    <a className="ViewButton" id="BudgetButton" href="https://billing.humbleservers.com/index.php?rp=/store/budget-vps-na">Buy</a>
                </div>
                <div className="UltimateDescription Descriptions">
                    <div className="PackageHeading">
                        <h2>Ultimate</h2>
                        <p>$3.25/GB</p>
                    </div>
                    <div className="Features">
                        <li><box-icon type='solid' name='bolt' color='#D5D5D5'></box-icon>          <p>Ryzen 7700 or equivalent</p></li>
                        <li><box-icon name='cloud-upload' color='#D5D5D5' ></box-icon>              <p>Upto 500Mbps Uplink</p></li>
                        <li><box-icon name='dashboard' type='solid' color='#D5D5D5' ></box-icon>    <p>Virtualizor Panel</p>  </li>
                        <li><box-icon name='terminal' color='#D5D5D5' ></box-icon>                  <p>KVM Access</p></li>
                        <li><box-icon name='lock-alt' type='solid' color='#D5D5D5' ></box-icon>     <p>Unmanaged</p> </li>
                    </div>
                    <a className="ViewButton" id="UltimateButton" href="https://billing.humbleservers.com/index.php?rp=/store/virtual-private-servers-eu">Buy</a>
                </div>
            </div>

                <br/>
                <br/>
                <br/>
                <h1 className="FeatureHeading">Features with each server!</h1>

                <div className="FeatureCards">
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={database} alt="cloud"/>
                            <h3>Unmetered Data</h3>
                        </div>
                        <p>Unleash the full potential of your server with unmetered bandwidth - no more worries about exceeding data limits.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={settings} alt="mods"/>
                            <h3>VNC Access</h3>
                        </div>
                        <p>Experience remote server management with VNC - access your server from anywhere, anytime with ease.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={settings} alt="settings"/>
                            <h3>KVM Server</h3>
                        </div>
                        <p>Experience unparalleled performance with KVM - harness the power of dedicated resources for your server.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={discord} alt="discord"/>
                            <h3>Discord Support</h3>
                        </div>
                        <p>24/7 Discord support just a message away - get help anytime, anywhere.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={settings} alt="settings"/>
                            <h3>SSH Access</h3>
                        </div>
                        <p>Have the server at your commands and effortlessly manage your server files with easy and full SSH access.</p>
                    </div>
                    <div className="Reason">
                        <div className="ReasonHeading">
                            <img src={database} alt="database"/>
                            <h3>Unmanaged</h3>
                        </div>
                        <p>Be worry free about privacy. Your server is unmanaged and private to you!</p>
                    </div>
                </div>
        </div>
        </Layout>
    )
}
